body {
  background-color: black;
  overflow: hidden;
}

.login-dialog-title {
  padding-top: 20px;
  padding-left: 40px;
  text-align: center;
  width: 100%;
  flex: 1;
}

#login-content-bg {
  position: absolute;
  z-index: 0;
  border: 0 !important;
  background-color: black;
  background-image: url(./assets/bg1.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#content-bg {
  background: black;
}

#login-content {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  /* animation: fadeIn 0.5s forwards; */
}

#spectrum-wrapper {
  border: 1px solid black;
  border-radius: 12px;
  background-color: black;
}

#navbar {
  position: fixed; 
  top: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  background: black;
}

#navbar-inner {
  padding-left: 24px;
  padding-right: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: white;
}

#sidebar {
  margin-top: 50px;
  height: calc(100vh - 50px);
  border-bottom-left-radius: 12px;
}

#content {
  margin-top: 50px;
  border-bottom-right-radius: 12px;
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.token-preview {
  overflow-wrap: anywhere;
  word-break: break-all;

}

@keyframes blurIn {
  from {
    filter: blur(0px);
  }
  to {
    filter: blur(10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

